<template>
    <div>
        <img :src="imageUrl" alt="">
        <LiefengModal
        width="600px"
        height="500px"
        :value="value"
        @input="(status) => value=status"
    >
        <template #contentarea>
            <LiefengCropper ref="cropper" width="580px" height="428px" v-if="value" @getImgUrl="getImgUrl" :imageUrl="imageUrl"></LiefengCropper>
        </template>
        <template #toolsbar>
            <Button @click="value = false" style="margin-right: 10px">取消</Button>
            <Button @click="save">裁剪</Button>
        </template>
        
    </LiefengModal>
    <Button @click="openModal">裁剪</Button>
    </div>
</template>

<script>
//@route('/testcropper')
import LiefengCropper from '@/components/LiefengCropper'
import LiefengModal from '@/components/LiefengModal'
   export default {
       data() {
            return {
              value: false,
              imageUrl: 'https://liefeng.oss-cn-shenzhen.aliyuncs.com/TEST/zjsm/20210316/bmdmp4b4Tulips.jpg'
           }
        },
        methods: {
            openModal() {
                this.value = true;
            },
            getImgUrl(url) {
                this.imageUrl = url + '?v=' + Math.random();
                this.value = false;
            },
            save() {
                this.$refs.cropper.save();
            }
        },
        components: {
            LiefengCropper,LiefengModal
        }
      
    }
</script>
    
<style scoped lang='less'>
    
</style>